/**
 *   Created by Nicolas Marchal on 08/06/2021
 *   Copyright © 2021 NM. All rights reserved.
 */

import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
} from 'react-admin';

export const FormList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <EmailField source="email"/>
      <TextField source="os"/>
      <TextField source="appName"/>
      <TextField source="firstName"/>
      <TextField source="postalCode"/>
      <TextField source="city"/>
      <TextField source="country"/>
      <TextField source="product" />
      <DateField source="createdAt"/>
    </Datagrid>
  </List>
)
