import './App.css';
import {Admin, Resource} from 'react-admin';
import authProvider from "./auth/AuthProvider";
import {FormList} from "./form/Form"
import simpleRestProvider from 'ra-data-simple-rest';
import httpClient from "./data/HttpClient";

function App() {
  return (
    <Admin
      dataProvider={simpleRestProvider('https://app.motionconcept-group.com/api/v1', httpClient)}
      authProvider={authProvider}>
      <Resource name="forms" list={FormList}/>
    </Admin>
  );
}

export default App;
